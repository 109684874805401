import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import phone from '../assets/images/phone.png';

interface Props {
}

const HomeTools: FC<Props> = ({}) => {
  const {t} = useTranslation();
  return (
    <section className='home__section_tools'>
      <h2 dangerouslySetInnerHTML={{__html: t('HOME_TITLE_TOOLS') || ''}}/>
      <p className='text-muted position-relative z-1' style={{maxWidth: 320}}>{t('HOME_TEXT_TOOLS')}</p>
      <div className=' home__tools_wrap'>
        <div className="home__tools_bg"/>
        <div className="home__tools">
          <div className="row">
            {Array(4).fill({}).map((_, i) => (
              <div className="col-12 col-lg-6" key={i}>
                <div className='home__tools_item'>
                  <div className='home__tools_item-idx'>{i + 1}</div>
                  <p>
                    <span className='text-tint'>{t(`HOME_TOOLS_ITEM_${i + 1}_TITLE`)}:</span>
                    <span className='text-muted'> {t(`HOME_TOOLS_ITEM_${i + 1}_TEXT`)}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className='home__tools_phone'>
            <div className="home__tools_circle" />
            <div className="home__tools_circle2" />
            <img src={phone} alt="phone"/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeTools;