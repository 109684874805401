import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {privacyHref, termsHref} from "../../modules/api";
import lang_svg from "../../assets/icons/world.svg";
import {Select} from "../FormControls";
import {ELanguage} from "../../translates/i18n";

interface Props {
}

const Footer: FC<Props> = () => {
  const {t, i18n} = useTranslation();


  const handleChangeLanguage = (ln: string) => {
    localStorage.setItem("ln", ln);
    i18n.changeLanguage(ln);
  }

  return (
      <footer>
        <div className="home__footer">
          <div className='text-muted-14'>© 2024-2025 Noodz.</div>

          <div className='home__footer-right'>
            <Select
              className='change__lang_select'
              icon={lang_svg}
              value={i18n.language}
              onChange={(e) => handleChangeLanguage(e.target.value)}
            >
              {Object.entries(ELanguage).map(([lang, ln]) => (
                <option value={ln} key={ln}>{lang}</option>
              ))}
            </Select>
            <a href={privacyHref} target='_blank' className='text-muted-14'>{t('PRIVACY_POLICY')}</a>
            <a href={termsHref} target='_blank' className='text-muted-14'>{t('TERMS_OF_SERVICE')}</a>
          </div>
          {/*<div className='text-14'>*/}
          {/*  <span className='text-muted'>{t('COMPANY_C')} - </span><Link to={'/sitemap'}>{t('SITEMAP')}</Link>*/}
          {/*</div>*/}
        </div>
      </footer>
  );
}

export default Footer;