import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button";
import arrow from '../../../../assets/icons/arrow-tr_w.svg';
import {backendUrl, loginHref, registerHref} from "../../../../modules/api";
import {thumbAvatar} from "../../../../modules/utils";


interface Props {
  onSignUp: () => void;
}

const HomeSection1_Top: FC<Props> = ({onSignUp}) => {
  const {t, i18n} = useTranslation();
  const [models, setModels] = useState<any[]>([]);

  useEffect(() => {
    fetchModels();
  }, []);

  const fetchModels = async () => {
    try {
      // const res = await  API.Models.getMainModels({limit: 3, gender: EGender.Female});
      const payload = {gender: 'female', limit: 3, fields: 'model:full'}
      const endpoint = '?__payload=' + encodeURIComponent(JSON.stringify(payload));
      const response = await fetch(backendUrl+'/models/main' + endpoint,{
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Accept-Language': i18n.language,
        },
      });
      const res = await response.json();
      setModels(res.result);
    } catch (e) {

    }
  }
  return (
    <section className='home__section_top'>
      <div className='home__section_top-content'>
        <div className="home__top_bg">
          <div className='light x1'></div>
          <div className='light x2'></div>
          <div className='light x3'></div>
          <div className='light x4'></div>
          {/*<div className='light x5'></div>*/}
          <div className='light x6'></div>
          {/*<div className='light x7'></div>*/}
          <div className='light x8'></div>
          <div className='light x9'></div>
        </div>
        {models.map((item, i) => (
          <a
            href={`https://noodz.ai/conversation/start/${item.id}`}
            target='_blank'
             className={`home__top_model home__top_model_${i + 1}`}
             key={item.id}
          >
            <div className="home__top_avatar">
              <img src={thumbAvatar(item.image?.id, 500)} alt="girl"/>
            </div>
            <div className="home__top_message ">
              <span className='text-truncate-2'>
              {t(`HOME_MESSAGE_${i+1}`)}
                </span>
            </div>
          </a>
        ))}

        <h1 dangerouslySetInnerHTML={{__html: t('HOME_TITLE') || ''}}/>
        <p className='text-muted text-balance' style={{maxWidth: 520}}>{t('HOME_SUB_TITLE')}</p>
        <Button
          href={registerHref}
          target='_blank'
          // onClick={onSignUp}
          icon={arrow}
          title='SIGN_UP_NOW'
        />
      </div>
    </section>
  );
}

export default HomeSection1_Top;