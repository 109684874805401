import React, {FC } from 'react';
import HomeSection1_Top from "./components/HomeSection1_Top";
import HomeSection2_Program from "./components/HomeSection2_Program";
import HomeSection3_HowItWorks from "./components/HomeSection3_HowItWorks";
import HomeSection4_FAQ from "./components/HomeSection4_FAQ";
import HomeSection5_Join from "./components/HomeSection5_Join";
import HomeTools from "./components/HomeTools";
import HomeStat from "./components/HomeStat";

interface Props {
  handleSignUp: () => void;
}

const HomePage: FC<Props> = ({handleSignUp}) => {


  return (
    <main className='home__page'>
      <HomeSection1_Top onSignUp={handleSignUp}/>
      <HomeSection2_Program onSignUp={handleSignUp}/>
      <HomeTools/>
      <HomeStat/>
      <HomeSection3_HowItWorks onSignUp={handleSignUp}/>
      <HomeSection4_FAQ/>
      {/*<HomeSection5_Join/>*/}

    </main>
  );
}

export default HomePage;