import React, {FC} from 'react';
import logo from '../../assets/images/logo.png';
import Button from "../Button";
import {TAuthType} from "../AuthForm/AuthForm";
import {loginHref, registerHref} from "../../modules/api";
import lang_svg from "../../assets/icons/world.svg";
import {ELanguage} from "../../translates/i18n";
import {Select} from "../FormControls";
import {useTranslation} from "react-i18next";

interface Props {
  handleSignUp: (type: TAuthType) => void;
}

const Header: FC<Props> = ({handleSignUp}) => {
  const {t, i18n} = useTranslation();


  const handleChangeLanguage = (ln: string) => {
    localStorage.setItem("ln", ln);
    i18n.changeLanguage(ln);
  }

  return (
    <header>
      <img src={logo} alt="logo" className='header__logo'/>
      <div className='d-flex gap-2'>
        <Select
          className='change__lang_select me-3'
          icon={lang_svg}
          value={i18n.language}
          onChange={(e) => handleChangeLanguage(e.target.value)}
        >
          {Object.entries(ELanguage).map(([lang, ln]) => (
            <option value={ln} key={ln}>{lang}</option>
          ))}
        </Select>
        <Button
          href={registerHref}
          target='_blank'
          // onClick={() => handleSignUp("register")}
          title='SIGN_UP'
        />
        <Button
          href={loginHref}
          target='_blank'
          // onClick={() => handleSignUp("login")}
          btnType='secondary'
          title='LOGIN'
        />
      </div>
    </header>
  );
}

export default Header;