import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
}

const HomeStat: FC<Props> = ({}) => {
  const {t} = useTranslation();
  return (
    <section className='home__section_stat'>
      <h2 dangerouslySetInnerHTML={{__html: t('HOME_TITLE_STAT') || ''}}/>
      <p className='text-muted mx-auto' style={{maxWidth: 320}}>{t('HOME_TEXT_STAT')}</p>
      <div className="row home__stat_list g-3">
        {Array(3).fill({}).map((_, i) => (
          <div className='col-12 col-lg-4' key={i}>
            <div className="home__stat_item">
              <p className='text-muted pre-wrap'>{t(`HOME_STAT_ITEM_${i + 1}_TEXT`)}</p>
              <h4 className='pre-wrap mt-4' dangerouslySetInnerHTML={{__html: t(`HOME_STAT_ITEM_${i + 1}_TITLE`)!}} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default HomeStat;